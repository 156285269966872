import React from 'react';
import { withRouter } from 'next/router';
import Footer from '@layout/home-footer/Footer';

import './Home.css';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = { toggle: false };
  }

  setToggle = () => {
    const { toggle } = this.state;
    this.setState({ toggle: !toggle });
  };

  render() {
    const { router } = this.props;

    return (
      <div>
        <nav className='navbar navbar-expand-lg navbar-light container'>
          <a className='navbar-brand' href='/'>
            <img src='/images/logo.svg' alt='logo' />
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => {
              this.setToggle();
            }}
          >
            <span className='navbar-toggler-icon' />
          </button>

          <div
            style={{ display: this.state.toggle ? 'block' : 'none' }}
            className='collapse navbar-collapse'
            id='navbarSupportedContent'
          >
            <ul className='navbar-nav ml-auto mr-3'>
              <li className='nav-item'>
                <a className='nav-link pointer' href='/login'>
                  Log In
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <div className='container index-container'>
          <div className='row justify-content-center pt-5'>
            <div className='col-6 text-center' />
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-6 text-center'>
              <img
                src='/images/sub-logo.svg'
                alt='sub-logo'
                className='mb-3 w-90'
              />
              <img
                src='/images/description.svg'
                alt='desc-logo'
                className='my-4 w-90'
              />
            </div>
          </div>
          <div className='row justify-content-center mb-5 mt-3'>
            <div className='col-8 col-sm-4 col-md-4 col-lg-3 text-center'>
              <button
                hidden
                className='btn btn-primary p-3'
                onClick={() => router.push('/register')}
              >
                Get Corporate Card
              </button>
            </div>
          </div>
          <div className='row justify-content-center pt-3'>
            <div className='col-8 col-sm-6 col-md-6 col-lg-6 text-center'>
              <img className='w-100' src='/images/mastercard.png' alt='' />
            </div>
          </div>
          <div className='footer-home-absolute'>
            <Footer />
          </div>
        </div>
        <img
          src='/images/l-obj.svg'
          className='v-obj-left d-none d-lg-block'
          alt=''
        />
        <img
          src='/images/r-obj.svg'
          className='v-obj-right d-none d-lg-block'
          alt=''
        />
      </div>
    );
  }
}

export default withRouter(Home);

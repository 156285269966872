import React from 'react';
import Home from '@containers/home/Home';
import HeadDefault from '@layout/head/HeadDefault';

const Index = () => (
  <div>
    <HeadDefault title='Apollo' description='' />
    <Home />
  </div>
);

export default Index;
